import { useMutation, useQuery } from '@apollo/client'
import { Breadcrumbs, Button, Grid, Icon, Link, Typography } from '@material-ui/core'
import HelpButton from 'components/HelpButton'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import EmptyMessage from 'components/Messaging/EmptyMessage'
import PageHeaderPath from 'components/PageHeaderPath'
import gql from 'graphql-tag'
import MaterialTable, { MTableToolbar } from 'material-table'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { GET_CANDIDATES2 } from '_queries/CANDIDATES'
import { autoCloseDelay, msgs, tableLocalization } from '_utils/appConstants'
import config from '_utils/config'
import history from '_utils/history'
import { dateFormat, toLocalDate } from '_utils/moment'
let toastId
const notifyProgress = () => (toastId = toast('Processando...', { autoClose: false }))
const success = (result) => {
  toast.update(toastId, {
    render: msgs.operationSuccess,
    type: toast.TYPE.SUCCESS,
    autoClose: autoCloseDelay
  })
}
const error = (exception) => {
  console.error(exception)
  toast.update(toastId, {
    render: msgs.operationError,
    type: toast.TYPE.ERROR,
    autoClose: autoCloseDelay
  })
}
export function CandidateList({ columns, options, onSelectionChange }) {
  document.title = 'Question - Avaliados'
  const tableRef = React.createRef()
  const [sortOrder, setSortOrder] = React.useState('DESC')
  const [_pageSize, setPageSize] = React.useState(10)
  const { loading, data, fetchMore } = useQuery(GET_CANDIDATES2, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: { paging: { size: _pageSize, page: 0, sort: 'createdAt', direction: 'DESC' } }
  })
  const [deleteCandidate] = useMutation(DELETE_CANDIDATE, {
    name: 'deleteCandidate',
    update: (cache, { data: { deleteCandidate } }) => {
      cache.modify({
        fields: {
          pagedAllCandidates(ref, { readField }) {
            return ref.content.filter((pagedCandidateRef) => {
              return deleteCandidate.id !== readField('id', pagedCandidateRef)
            })
          }
        }
      })
      // ✅ Ensure `tableRef.current` exists before calling `onQueryChange`
      if (tableRef.current && typeof tableRef.current.onQueryChange === 'function') {
        tableRef.current.onQueryChange();
      }
    }
  })

  const remoteData = (query) => {
    return new Promise((resolve, reject) => {
      setPageSize(query.pageSize)
      setSortOrder(query.orderDirection.toUpperCase() === 'ASC' ? 'DESC' : 'ASC')
      fetchMore({
        variables: {
          search: query.search,
          paging: {
            size: query.pageSize,
            page: query.page,
            sort: query.orderBy && query.orderBy.field,
            direction: sortOrder
          }
        }
      }).then((r) => {
        const { pagedAllCandidates } = r.data
        resolve({
          data: pagedAllCandidates.content,
          page: query.page,
          totalCount: pagedAllCandidates.total
        })
      })
    })
  }
  return data && data.pagedAllCandidates && data.pagedAllCandidates.content.length === 0 ? (
    <Grid container justify="center">
      <EmptyMessage
        title="Nenhum avaliado encontrado"
        subtitle="Começe registrando um novo!"
        action={
          <Link to="/new_candidate" component={RouterLink}>
            <Button startIcon={<Icon>add</Icon>}>Novo Avaliado</Button>
          </Link>
        }
      />
    </Grid>
  ) : (
    <MaterialTable
      tableRef={tableRef}
      localization={tableLocalization}
      title={'Avaliados'}
      isLoading={loading}
      options={{
        ...options,
        pageSize: _pageSize,
        actionsColumnIndex: -1,
        exportButton: true,
        padding: 'dense',
        search: true,
        debounceInterval: 500
      }}
      onSelectionChange={onSelectionChange ? onSelectionChange : void 0}
      columns={
        columns
          ? columns
          : [
            {
              title: 'Nome',
              field: 'fullName',
              grouping: false
            },
            {
              title: 'Email',
              field: 'email',
              grouping: false
            },

            {
              title: 'Contato',
              field: 'mobile',
              grouping: true
            },
            {
              title: 'Criação',
              field: 'createdAt',
              type: 'datetime',
              render: (rowData) =>
                rowData.createdAt && toLocalDate(rowData.createdAt, dateFormat)
            },
            {
              title: 'Atualizado',
              field: 'updatedAt',
              type: 'datetime',
              render: (rowData) =>
                rowData.updatedAt && toLocalDate(rowData.updatedAt, dateFormat)
            }
          ]
      }
      data={remoteData}
      components={{
        Toolbar: (toolbarProps) => (
          <div style={{ margin: '10px' }}>
            <MTableToolbar {...toolbarProps} />
          </div>
        )
      }}
      actions={[
        (rowData) => ({
          icon: 'visibility',
          tooltip: 'Visualizar Avaliado',
          onClick: (event, data) => {
            history.push('/candidates/view/' + rowData.id)
          }
        }),
        (rowData) => ({
          icon: 'edit',
          tooltip: 'Editar Avaliado',
          onClick: (event, data) => {
            history.push('/candidates/edit/' + rowData.id)
          }
        }),
        (rowData) => ({
          icon: 'delete',
          tooltip: 'Remover Avaliado',
          onClick: (event, data) => {
            Swal.fire({
              title: 'Tem certeza que deseja remover?',
              text: 'Não será possível desfazer está ação!',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#FE5000',
              cancelButtonColor: '#d33',
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Sim, remover!'
            }).then((result) => {
              if (result.value) {
                notifyProgress()
                deleteCandidate({
                  variables: {
                    id: rowData.id
                  }
                })
                  .then((results) => {
                    results.data.deleteCandidate && results.data.deleteCandidate.deleted
                      ? success(results)
                      : error(msgs.operationError)
                  })
                  .catch((exception) => error(exception))
              }
            })
          }
        })
      ]}
    />
  )
}

function CandidatesPage(props) {
  return (
    <Grid container justify="center">
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Typography>Avaliados</Typography>{' '}
          <Link to="/new_candidate" component={RouterLink}>
            <Button size="small" disableElevation startIcon={<Icon>add</Icon>}>
              Novo Avaliado
            </Button>
          </Link>{' '}
        </Breadcrumbs>{' '}
        <HelpButton link={config.tutorials.TUTORIAL_CANDIDATE_PAGE} />
      </PageHeaderPath>
      <Grid item lg={12} xs={12}>
        <CandidateList />
      </Grid>
    </Grid>
  )
}

const DELETE_CANDIDATE = gql`
  mutation DELETE_CANDIDATE($id: Long!) {
    deleteCandidate(id: $id) {
      id
      deleted
    }
  }
`

export default CandidatesPage
