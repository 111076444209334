import { useMutation, useQuery } from '@apollo/client'
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Icon,
  Link,
  TextField,
  Typography
} from '@material-ui/core'
import HelpButton from 'components/HelpButton'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import Loading from 'components/Loading'
import EmptyMessage from 'components/Messaging/EmptyMessage'
import AlertDialogSlide from 'components/Messaging/SimpleDialog'
import PageHeaderPath from 'components/PageHeaderPath'
import gql from 'graphql-tag'
import MaterialTable, { MTableToolbar } from 'material-table'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { handleApolloErrors } from '_services/apollo'
import {
  autoCloseDelay,
  msgs,
  pageSize,
  pageSizeOptions,
  tableLocalization
} from '_utils/appConstants'
import config from '_utils/config'
import history from '_utils/history'
import { toLocalDate, toTime } from '_utils/moment'
const turl = require('turl')
let toastId = null
const notifyProgress = () => (toastId = toast('Processando...', { autoClose: false }))
const success = (result) => {
  toast.update(toastId, {
    render: msgs.operationSuccess,
    type: toast.TYPE.SUCCESS,
    autoClose: autoCloseDelay
  })
}
const error = (exception) => {
  console.error(exception)
  toast.update(toastId, {
    render: msgs.operationError,
    type: toast.TYPE.ERROR,
    autoClose: autoCloseDelay
  })
}
function ResultsPage(props) {
  document.title = 'Question - Resultados'
  const searchUrlData = new URLSearchParams(window.location.search)
  const assessmentId = searchUrlData.get('assessmentId')
  let initialFilter = [
    {
      column: {
        field: 'assessment.id'
      },
      value: [assessmentId],
      operator: '='
    }
  ]
  const tableRef = React.createRef()
  const [sortOrder, setSortOrder] = React.useState('DESC')
  const [showShareDialog, setShowShareDialog] = React.useState(false)
  const [dialogValue, setDialogValue] = React.useState('')
  const [_pageSize, setPageSize] = React.useState(pageSize)
  const [empty, setEmpty] = React.useState(false)
  let testTitileLookup = {}
  const { loading, fetchMore } = useQuery(GET_ASSESSMENT_RESULTS2, {
    variables: {
      paging: {
        size: _pageSize,
        page: 0
      }
    },
    skip: true,
    onError: (_error) => handleApolloErrors(_error)
  })
  const [resetTestLink] = useMutation(RESET_TEST_LINK, {
    name: 'resetTestLink',
    update: (cache, { data: { resetTestLink } }) => {
      cache.modify({
        fields: {
          pagedCandidateAssessments(existingAllAssessmentsRef = [], { readField }) {
            if (
              existingAllAssessmentsRef.content.some(
                (ref) => readField('id', ref) === resetTestLink.id
              )
            ) {
              return existingAllAssessmentsRef
            }
            return [...existingAllAssessmentsRef.content, resetTestLink]
          }
        }
      })
      // ✅ Ensure `tableRef.current` exists before calling `onQueryChange`
      if (tableRef.current && typeof tableRef.current.onQueryChange === 'function') {
        tableRef.current.onQueryChange();
      }
    }
  })
  const [archive] = useMutation(ARCHIVE_CANDIDATE_ASSESSMENT, {
    name: 'archiveCandidateAssessment',
    update: (cache, { data: { archiveCandidateAssessment } }) => {
      cache.modify({
        fields: {
          archiveCandidateAssessment(ref, { readField }) {
            return ref.content.filter((item) => {
              return archiveCandidateAssessment.id !== readField('id', item)
            })
          }
        }
      })
      // ✅ Ensure `tableRef.current` exists before calling `onQueryChange`
      if (tableRef.current && typeof tableRef.current.onQueryChange === 'function') {
        tableRef.current.onQueryChange();
      }
    }
  })

  const remoteData = (query) => {
    return new Promise((resolve, reject) => {
      setPageSize(query.pageSize)
      query.orderDirection && setSortOrder(query.orderDirection.toUpperCase())
      fetchMore({
        variables: {
          search: query.search,
          filters: {
            columns: assessmentId ? initialFilter : query.filters
          },
          paging: {
            size: query.pageSize,
            page: query.page,
            sort: (query.orderBy && query.orderBy.field) || void 0,
            direction: sortOrder
          }
        }
      })
        .then((r) => {
          const { pagedCandidateAssessments } = r.data
          pagedCandidateAssessments.content.forEach((item) => {
            testTitileLookup[item.assessment.id] = item.assessment.title
          })
          setEmpty(pagedCandidateAssessments.total === 0)
          resolve({
            data: pagedCandidateAssessments.content,
            page: query.page,
            totalCount: pagedCandidateAssessments.total
          })
        })
        .catch((_e) => {
          resolve({
            data: []
          })
          handleApolloErrors(_e)
        })
    })
  }
  const ShareBody = () => {
    return (
      <Grid item xs={12}>
        <TextField value={dialogValue} fullWidth={true} label="Link curto" />
      </Grid>
    )
  }

  return (
    <Container disableGutters maxWidth="xl">
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Typography>Resultados</Typography>
        </Breadcrumbs>{' '}
        <HelpButton link={config.tutorials.TUTORIAL_RESULT_PAGE} />
      </PageHeaderPath>
      {empty ? (
        <Grid container justify="center">
          <EmptyMessage
            title="Nenhum resultado encontrado"
            subtitle="Comece enviando um teste para um avaliado!"
            action={
              <Link to="/tests" component={RouterLink}>
                <Button startIcon={<Icon>launch</Icon>}>Aplicar Teste</Button>
              </Link>
            }
          />
        </Grid>
      ) : (
        <Grid item md={12}>
          {loading ? (
            <Loading />
          ) : (
            <Grid item lg={12} xs={12}>
              <React.Fragment>
                <MaterialTable
                  tableRef={tableRef}
                  localization={tableLocalization}
                  title={'Resultados'}
                  options={{
                    pageSize: _pageSize,
                    grouping: true,
                    sorting: true,
                    customSort: true,
                    padding: 'dense',
                    pageSizeOptions: pageSizeOptions,
                    exportButton: true,
                    filtering: true,
                    debounceInterval: 500
                  }}
                  columns={[
                    // {
                    //   title: 'Execução',
                    //   field: 'id',
                    //   type: 'numeric',
                    //   filtering: false,
                    //   grouping: false
                    // },
                    {
                      title: 'Teste',
                      field: 'assessment.id',
                      filtering: true,
                      cellStyle: {
                        width: 200
                        // minWidth: 200
                      },
                      lookup: testTitileLookup,
                      render: (rowData) => {
                        return (
                          (rowData.candidate && (
                            <>
                              <Link
                                to={'/tests/visualization/' + rowData.assessment.id}
                                component={RouterLink}>
                                <Typography variant="body2" component={'span'}>
                                  {rowData.assessment.title}
                                </Typography>
                              </Link>
                              <Typography variant="caption" component={'h6'}>
                                {rowData.id} -{' '}
                                {rowData.assessment.testDurationInMinutes
                                  ? rowData.assessment.testDurationInMinutes + ' Minutos'
                                  : ''}
                              </Typography>
                            </>
                          )) ||
                          rowData
                        )
                      }
                    },
                    {
                      title: 'Situação',
                      field: 'status.name',
                      // type: 'string',
                      sorting: false,
                      filtering: false,
                      grouping: true,
                      cellStyle: (status) => {
                        let clr = 'red'
                        switch (status) {
                          case 'Concluído':
                            clr = 'green'
                            break
                          case 'Expirado':
                            clr = 'gray'
                            break
                          default:
                            clr = 'red'
                        }
                        return { color: clr }
                      },
                      render: (rowData) => {
                        return rowData.status && rowData.status.name
                      }
                    },

                    {
                      title: 'Avaliado/Notificação',
                      field: 'candidate.fullName',
                      type: 'string',
                      headerStyle: {
                        width: 200,
                        minWidth: 200
                      },
                      cellStyle: {
                        width: 200,
                        minWidth: 200
                      },
                      filtering: false,
                      grouping: true,
                      render: (rowData) => {
                        return (
                          <div>
                            {rowData.candidate && (
                              <Link
                                to={'/candidates/view/' + rowData.candidate.id}
                                component={RouterLink}>
                                {rowData.candidate.fullName}
                              </Link>
                            )}
                            <Typography variant="caption" component={'h6'}>
                              {rowData.emailDeliveryStatusTimestamp ? (
                                toLocalDate(rowData.emailDeliveryStatusTimestamp) +
                                ' - ' +
                                rowData.emailDeliveryStatus
                              ) : (
                                <span>Sem dados </span>
                              )}
                            </Typography>
                          </div>
                        )
                      }
                    },
                    // {
                    //   title: 'Avaliado',
                    //   filtering: false,
                    //   type: 'string',
                    //   field: 'candidate.fullName',
                    //   render: (rowData) => {
                    //     return (
                    //       (rowData.candidate && (
                    //         <Link
                    //           to={'/candidates/view/' + rowData.candidate.id}
                    //           component={RouterLink}>
                    //           {rowData.candidate.fullName}
                    //         </Link>
                    //       )) ||
                    //       rowData
                    //     )
                    //   }
                    // },
                    {
                      title: 'Operador',
                      filtering: false,
                      type: 'string',
                      field: 'sender.fullName'
                    },
                    {
                      title: 'Pontuação',
                      field: 'score',
                      filtering: false,
                      grouping: false,
                      render: (rowData) =>
                        Number(rowData.score).toLocaleString(undefined, {
                          style: 'percent',
                          minimumFractionDigits: 2
                        })
                    },

                    {
                      title: 'Envio',
                      field: 'sentAt',
                      filtering: false,
                      grouping: false,
                      type: 'date',
                      cellStyle: {
                        whiteSpace: 'nowrap'
                      },
                      render: (rowData) => {
                        return rowData.sentAt && toLocalDate(rowData.sentAt)
                      }
                    },
                    {
                      title: 'Início',
                      field: 'startedAt',
                      grouping: false,
                      filtering: false,
                      type: 'datetime',
                      cellStyle: {
                        whiteSpace: 'nowrap'
                      },
                      render: (rowData) => rowData.startedAt && toLocalDate(rowData.startedAt)
                    },
                    {
                      title: 'Conclusão',
                      field: 'completedAt',
                      grouping: false,
                      filtering: false,
                      type: 'datetime',
                      cellStyle: {
                        whiteSpace: 'nowrap'
                      },
                      render: (rowData) => rowData.completedAt && toLocalDate(rowData.completedAt)
                    },
                    {
                      title: 'Tempo',
                      type: 'time',
                      field: 'elapsedTime',
                      sorting: false,
                      filtering: false,
                      grouping: false,
                      render: (rowData) => {
                        return rowData.elapsedTime ? toTime(rowData.elapsedTime) : ''
                      }
                    },
                    {
                      title: 'Data Limite',
                      field: 'config.dueDateAt',
                      grouping: false,
                      filtering: false,
                      type: 'datetime',
                      headerStyle: {
                        width: 150,
                        minWidth: 150
                      },
                      cellStyle: {
                        width: 150,
                        minWidth: 150,
                        whiteSpace: 'nowrap'
                      },

                      render: (rowData) => {
                        return rowData.config.dueDateAt && toLocalDate(rowData.config.dueDateAt)
                      }
                    }
                  ]}
                  actions={[
                    () => ({
                      icon: 'delete',
                      tooltip: 'Arquivar Execução',
                      onClick: (event, rowData) =>
                        Swal.fire({
                          title: 'Tem certeza que deseja arquivar a execução?',
                          text:
                            'Esta ação impedirá que avaliados acessem o teste através do link informado durante o compartilhamento',
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#FE5000',
                          cancelButtonText: 'Cancelar',
                          confirmButtonText: 'Confirmar'
                        }).then((result) => {
                          if (result.value) {
                            notifyProgress()
                            archive({
                              variables: {
                                id: rowData.id
                              }
                            })
                              .then((results) => success(results))
                              .catch((exception) => error(exception))
                          }
                        })
                    }),
                    (rowData) => ({
                      icon: 'share',
                      tooltip: 'Enviar Por Whatsapp',
                      hidden: !rowData.candidate.mobile || !rowData.config.executionEnabled,
                      onClick: (event, rowData) => {
                        turl
                          .shorten(rowData.mailLink)
                          .then((res) => {
                            var win = window.open(
                              `https://web.whatsapp.com/send?phone=+55${rowData.candidate.mobile}&text=Segue o link para o teste no sistema de avaliação ${res}&app_absent=0`,
                              '_blank'
                            )
                            win.focus()
                          })
                          .catch((err) => {
                            console.error(err)
                          })
                      }
                    }),
                    (rowData) => ({
                      hidden: rowData.completedAt,
                      icon: 'cached',
                      tooltip: 'Resetar Link e Reenviar',
                      onClick: (event, data) => {
                        Swal.fire({
                          title: 'Tem certeza que deseja resetar o teste?',
                          text:
                            'Esta ação removerá as respostas coletadas anteriormente caso haja alguma e enviará o link de execução novamente ao usuário, esta ação não poderá ser desfeita',
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#FE5000',
                          cancelButtonText: 'Cancelar',
                          confirmButtonText: 'Confirmar'
                        }).then((result) => {
                          if (result.value) {
                            notifyProgress()
                            resetTestLink({
                              variables: {
                                id: data.id
                              }
                            })
                              .then((results) => {
                                success(results)
                              })
                              .catch((exception) => error(exception))
                          }
                        })
                      }
                    }),
                    (rowData) => ({
                      icon: 'link',
                      hidden: !rowData.config.executionEnabled || rowData.expired,
                      tooltip: 'Link Execução Curto',
                      onClick: (event) => {
                        turl
                          .shorten(rowData.mailLink)
                          .then((res) => {
                            setDialogValue(res)
                            setShowShareDialog(true)
                          })
                          .catch((err) => {
                            console.error(err)
                          })
                      }
                    }),
                    (rowData) => ({
                      icon: 'open_in_new',
                      hidden: !rowData.config.executionEnabled || rowData.expired,
                      tooltip: 'Abrir Link',
                      onClick: (event) => {
                        window.open(rowData.mailLink, '_blank')
                      }
                    }),
                    (rowData) => ({
                      icon: 'visibility',
                      tooltip: 'Visualizar Resultado',
                      onClick: (event) => {
                        history.push('/tests/results/' + rowData.id)
                      },
                      hidden: rowData.completedAt === null
                    })
                  ]}
                  data={remoteData}
                  components={{
                    Toolbar: (props) => (
                      <div>
                        <MTableToolbar {...props} />{' '}
                        {assessmentId && (
                          <Grid container justify="center">
                            <Button
                              color="default"
                              style={{ margin: 10 }}
                              onClick={() => (window.location.href = '/tests/results')}>
                              Listar todos os resultados
                            </Button>
                          </Grid>
                        )}
                      </div>
                    )
                  }}
                  isLoading={loading}
                />
              </React.Fragment>

              <AlertDialogSlide
                title="Link para execução(Individual)"
                open={showShareDialog}
                onClose={() => setShowShareDialog(false)}
                content={<ShareBody />}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Container>
  )
}

const GET_ASSESSMENT_RESULTS2 = gql`
  query pagedCandidateAssessments(
    $paging: PagingInput
    $search: String
    $filters: FilteringInput
  ) {
    pagedCandidateAssessments(paging: $paging, search: $search, filters: $filters) {
      pageable {
        pageNumber
        pageSize
        hasNext
        hasPrevious
      }
      total
      totalPages
      content {
        id
        score
        completedAt
        elapsedTime
        sentAt
        startedAt
        # dueDateAt
        # executionEnabled
        executionCount
        emailDeliveryStatus
        emailDeliveryStatusTimestamp
        expired
        status {
          name
        }
        available
        mailLink
        sender {
          id
          fullName
        }
        tags {
          id
          name
        }
        assessment {
          id
          title
          testDurationInMinutes
        }
        candidate {
          id
          fullName
          email
          mobile
        }
        config {
          dueDateAt
          executionEnabled
        }
      }
    }
  }
`
const RESET_TEST_LINK = gql`
  mutation resetTestLink($id: Long!) {
    resetTestLink(id: $id) {
      id
      score
      completedAt
      sentAt
      expired
      # executionEnabled
      mailLink
      config {
        executionEnabled
      }
    }
  }
`
const ARCHIVE_CANDIDATE_ASSESSMENT = gql`
  mutation archiveCandidateAssessment($id: Long!) {
    archiveCandidateAssessment(id: $id) {
      id
      deleted
    }
  }
`
export default ResultsPage
