import React, { useContext } from 'react'
import { Grid, CircularProgress, Button, Box, Paper, Icon } from '@material-ui/core'
import { Form, Field, Formik } from 'formik'
import { CustomTextField } from 'components/Layout/FormFields'
import { toast } from 'react-toastify'
import { masks, msgs, autoCloseDelay } from '_utils/appConstants'
import FormHeading from 'components/Layout/FormHeading'
import { UPDATE_PROFILE } from '_queries/USER'
import { useMutation } from '@apollo/client'
import { ProfileContext } from 'components/Layout/Navigation/Navigation'
let toastId = -1
function DetailsDataForm(classProps) {
  const notifyProgress = () =>
    (toastId = toast('Processando...', {
      autoClose: false
    }))
  const success = (results) => {
    console.debug(results)
    toast.update(toastId, {
      render: msgs.operationSuccess,
      type: toast.TYPE.SUCCESS,
      autoClose: autoCloseDelay
    })
  }
  const error = (exception) => {
    console.error(exception)
    toast.update(toastId, {
      render: msgs.operationError,
      type: toast.TYPE.ERROR,
      autoClose: autoCloseDelay
    })
  }
  // eslint-disable-next-line
  const profile = useContext(ProfileContext)
  const [updateUserProfile] = useMutation(UPDATE_PROFILE, {
    name: 'updateUserProfile',
    options: (props) => ({
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        sessionStorage.setItem('me', JSON.stringify(data.updateUserProfile))
      }
    })
  })
  return (
    <Grid item md={6} xs={12}>
      <Box p={3} component={Paper}>
        <FormHeading>Detalhes </FormHeading>
        <Formik
          enableReinitialize={false}
          initialValues={{ ...profile }}
          onSubmit={(values, actions, isSubmitting) => {
            notifyProgress()
            updateUserProfile({
              variables: {
                request: {
                  fullName: values.fullName,
                  id: values.id,
                  password: values.password,
                  tenant: {
                    id: values.tenant.id,
                    organization: values.tenant.organization,
                    phone: values.tenant.contactPhone,
                    document: values.tenant.document
                  }
                }
              }
            })
              .then((results) => {
                results.data.updateUserProfile ? success(results) : error(msgs.operationError)
              })
              .catch((exception) => {
                error(exception)
              })
            actions.setSubmitting(false)
          }}>
          {(formikProps) =>
            profile && (
              <Form onSubmit={formikProps.handleSubmit} noValidate>
                <Field
                  name="tenant.organization"
                  label="Organização"
                  component={CustomTextField}
                />
                <Field
                  // disabled={profile.tenant.owner.email !== profile.email}
                  component={CustomTextField}
                  fullWidth={true}
                  label="Telefone Contato"
                  type={'text'}
                  name="tenant.contactPhone"
                  mask={masks.mobile}
                />
                <Field
                  mask={profile.tenant.documentType === 'CNPJ' ? masks.cnpj : masks.cpf}
                  name="tenant.document"
                  label={
                    profile.tenant.documentType === 'CNPJ'
                      ? 'CNPJ Responsável'
                      : 'CPF do Responsável'
                  }
                  component={CustomTextField}
                />
                <Field
                  disabled
                  component={CustomTextField}
                  fullWidth={true}
                  placeholder="Contato Principal"
                  label="Nome Contato"
                  type={'text'}
                  name="tenant.owner.fullName"
                />{' '}
                <Field
                  disabled
                  component={CustomTextField}
                  fullWidth={true}
                  placeholder="Email Contato Principal"
                  label="Email Contato"
                  type={'text'}
                  name="tenant.owner.email"
                />{' '}
                <p>Para alterar as informações acima, entre em contato com nosso suporte</p>
                <Grid container justify="center">
                  {formikProps.loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      variant="outlined"
                      disabled={formikProps.isSubmitting}
                      startIcon={<Icon>save</Icon>}>
                      Salvar
                    </Button>
                  )}
                </Grid>
              </Form>
            )
          }
        </Formik>
      </Box>
    </Grid>
  )
}

export default DetailsDataForm
