import React from 'react'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik, Field } from 'formik'

import * as userActions from '_actions/user.actions'
import { CircularProgress, Chip } from '@material-ui/core'
import { CustomTextField } from 'components/Layout/FormFields'
import LoginValidationSchema from './LoginValidationSchema'
import { support_format_webp } from '_utils/tools'
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: 'url(/images/bg3.jpeg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    textAlign: 'center',
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

const spacerStyle = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1)
    },
    textAlign: 'center'
  }
}))

export function LoginForm({ isSubmitting, handleSubmit }) {
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container justify="center">
        <img
          width="150px"
          height="auto"
          src={support_format_webp() ? '/images/logo.webp' : '/images/logo.png'}
          alt="logo"
        />
        <Field name="email" component={CustomTextField} label="Email" required autoFocus />
        <Field
          name="password"
          type="password"
          component={CustomTextField}
          label="Senha"
          required
          autoComplete="current-password"
        />
      </Grid>
      <Grid container item xs={12} md={12} justify="flex-end">
        <Link id="forgot-password-link" href="/forgot-password" variant="body2">
          Esqueceu a senha?
        </Link>
      </Grid>
      <br />
      <Grid container justify="center">
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <Button id="login-btn" type="submit" size="large" color="primary">
            Entrar
          </Button>
        )}
      </Grid>
      {process.env.REACT_APP_ENV_NAME !== 'Production' && (
        <Grid container justify="center">
          <br /> <Chip label={process.env.REACT_APP_ENV_NAME}></Chip>
        </Grid>
      )}
    </Form>
  )
}
export function SideLoginPage(props) {
  document.title = 'Question - Login'
  const classes = useStyles()
  const linkClasses = spacerStyle()
  return (
    <Grid container component="main" direction="row" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        xs={12}
        sm={8}
        md={5}
        item
        component={Paper}
        elevation={6}
        square
        container
        direction="row"
        alignContent="center"
        justify="center">
        <Grid item className={classes.paper} md={8} sm={12} xs={12}>
          <Formik
            initialValues={{
              email: props.email || '',
              password: ''
            }}
            validationSchema={LoginValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              props
                .dispatch(userActions.login(values, props.forwardUrl))
                .then(() => setSubmitting(false))
            }}>
            {(formProps) =>
              formProps.isSubmitting ? (
                <CircularProgress />
              ) : (
                <>
                  <LoginForm {...formProps} />{' '}
                  <Grid container className={linkClasses.root}>
                    <Grid item xs={12} md={12}>
                      {'Não possui uma conta? '}
                      <Link
                        href={
                          props.forwardUrl ? '/signup?forwardUrl=' + props.forwardUrl : '/signup'
                        }
                        variant="body2">
                        Cadastre-se
                      </Link>
                    </Grid>
                  </Grid>
                </>
              )
            }
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  )
}
function mapStateToProps(state, props) {
  const { authentication } = state
  const query = new URLSearchParams(props.location && props.location.search.replace('&', '~'))

  return {
    loading: authentication.loading,
    email: query.get('email') || '',
    password: query.get('password') || '',
    forwardUrl: (query.get('forwardUrl') && query.get('forwardUrl').replace('~', '&')) || ''
  }
}

export default connect(mapStateToProps)(SideLoginPage)
